import React from 'react';

/* STORYBOOK & PLUGINS */
import { storiesOf } from '@storybook/react';
// import { boolean, text } from '@storybook/addon-knobs';

/* STORYBOOK SETUP */
import { StoryPage } from 'ui/storybook/StoryPage';
import { IST_RESULTS } from 'ui/storybook/storybook.mockData';

/* COMPONENTS */
import MyCoaching from './MyCoaching';

/* STORE */
import { Provider } from 'react-redux';
import configureStore from 'redux-mock-store';

const mockStore = configureStore();

const store = mockStore({
  assessments: {
    recommendations: [],
  },
});


export const DUMMY_CARDS = [
  {
    type: 'coaching-booking',
    url: 'https://calendly.com/bluquist-ist-coaching-test/test-coaching',
    isPaid: false,
  },
  {
    type: 'upselling',
    url: 'https://calendly.com/bluquist-ist-coaching-test/test-coaching',
    state: 'open', // open, ordered, results pending, completed
  },
];

/* STORIES */
storiesOf('Pages/Overview(home)', module)
.add('MyCoaching', () => (
  <Provider store={store}>
    <StoryPage
      // eslint-disable-next-line max-len
      figmaLink='https://www.figma.com/file/y4VNjqlrhOI0atYG8eLBII/Product-Master---All-Pages-%26-Components?type=design&node-id=25368-346370&t=OpL60dV46yot8fN7-0'
      jiraLink='https://blueexcellence.atlassian.net/browse/BQP-945'
      title='MyCoaching'
    >
      <MyCoaching
        ist={IST_RESULTS}
        user={{
          mail: 'petra.arnold@bluquist.com',
          firstName: 'Petra',
          lastName: 'Arnold',
        }}
        cards={DUMMY_CARDS}
        showHelpCard
      />
    </StoryPage>
  </Provider>
));
