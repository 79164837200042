// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './StoryPage.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';
import { boolean, text, select } from '@storybook/addon-knobs/';


// OTHER COMPONENTS

// UTILS
import { getTranslate } from 'utils/translator';
import {
  useBreakpoint, breakpoints,
  useAutoReset
} from 'utils/hooks';
import {
  TranslateContext,
  translationsEnUs,
  translationsDeDe
} from '_sbShortcuts';


// STORE

// CONFIG & DATA

// COMPONENT: StoryPage
const StoryPage = (props) => {
  // PROPS
  const {
    // knobs
    title = 'Title',
    greyBackground = true,
    noPaddings = false,

    // tools
    figmaLink = '',
    jiraLink =  '',
    qaseLink = '',
    customLinks = '',
    onBreakpoint = (bp, setHighlight) => {},

    // children
    children,
  } = props;

  // KNOBS
  const titleKnob = text('StoryPage: title', title);
  const greyBackgroundKnob = boolean('StoryPage: greyBackground', greyBackground);
  const noPaddingsKnob = boolean('StoryPage: noPaddings', noPaddings);
  const translates = {
    English: getTranslate(translationsEnUs),
    Deutsch: getTranslate(translationsDeDe),
  };
  const language = select('StoryPage: language', {
    English: 'English',
    Deutsch: 'Deutsch',
  }, 'English');

  // BREAKPOINT HIGHLIGHT
  const [ highlight, setHighlight ] = useAutoReset(false, 100);
  const [ internalBp, setInternalBp ] = useState(breakpoints.Xl);
  const bp = useBreakpoint();

  useEffect(() => {
    if (bp.bpName !== internalBp.bpName) {
      // console.log(bp.bpName, bp);
      onBreakpoint(bp, setHighlight);
      setInternalBp(bp);
    }
  }, [bp, internalBp, onBreakpoint, setHighlight]);

  // COMPONENT/UI STATE and REFS

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES

  // HELPERS

  // RENDERS

  // RENDER: StoryPage
  return (
    <TranslateContext.Provider value={{
      translate: translates[language]
    }}>
      <div className={classNames(styles.storyPage, {
        [styles.greyBackground]: greyBackgroundKnob,
        [styles.noPaddings]: noPaddingsKnob,
        [styles.highlight]: highlight,
      })}>
        {/* TITLE */}
        { titleKnob && (
          <div className={styles.title}>
            <h1> { titleKnob } </h1>
            <hr/>
            { (figmaLink || jiraLink || customLinks) && (
              <div>
                { figmaLink && (<><a href={figmaLink} target="_blank" rel="noopener noreferrer">FigmaLink</a> | <>&nbsp;</></>)}
                { jiraLink && (<><a href={jiraLink} target="_blank" rel="noopener noreferrer">JiraLink</a> | <>&nbsp;</></>) }
                { qaseLink && (<><a href={qaseLink} target="_blank" rel="noopener noreferrer">qaseLink</a> | <>&nbsp;</></>) }
                { customLinks }
                <hr/>
              </div>
            )}
          </div>
        )}

        {/* CHILDREN */}
        { children && (
          <div className={styles.children}>
            { children }
          </div>
        )}
      </div>
    </TranslateContext.Provider>
  );
};

export default StoryPage;
